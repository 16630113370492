import Container from "@material-ui/core/Container"
import Paper, { PaperProps } from "@material-ui/core/Paper"
import React from "react"
import styled from "styled-components"

export type StyledFormProps = PaperProps & {
  isLoading: boolean
}

export const FormWrapper = styled(Container)`
  max-width: 580px;
`

export const StyledForm = styled(({ isLoading, ...props }) => (
  <Paper {...props} component="form" />
))<StyledFormProps>`
  cursor: ${({ isLoading }) => (isLoading ? "wait" : "pointer")};
  padding: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(4)}px`};

  > * {
    margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
    :last-child {
      display: flex;
    }
  }

  button {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    margin-left: auto;
  }

  .MuiOutlinedInput-root {
    border-radius: 0;
  }

  .noshow {
    display: none !important;
  }
`
