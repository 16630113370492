import { graphql, useStaticQuery } from "gatsby"
import { GraphQLClient } from "graphql-request"
import { getSdk } from "../__generated__/graphql-wp-sdk"
import { wpUrlQuery } from "./__generated__/wpUrlQuery"

const useGqlClient = () => {
  const data = useStaticQuery<wpUrlQuery>(graphql`
    query wpUrlQuery {
      wp {
        generalSettings {
          url
        }
      }
    }
  `)

  const { url } = { ...data.wp?.generalSettings }

  if (url) {
    const client = new GraphQLClient(`${url}/graphql`)
    const sdk = getSdk(client)

    return sdk
  } else {
    throw new Error("No backend graphql endpoint found in Gatsby")
  }
}

export default useGqlClient
