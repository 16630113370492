import MomentUtils from "@date-io/moment"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField, { TextFieldProps } from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import { Link } from "gatsby"
import React, { ReactNode, useEffect, useState } from "react"
import styled from "styled-components"
import useGqlClient from "../../../hooks/useGqlClient"
import { FormFieldNodeFragment, FormFragment } from "../../../__generated__/graphql-gatsby"
import {
  FormFieldNodeFragment as PreviewFormFieldNodeFragment,
  FormFragmentFragment as PreviewFormFragment,
  InputField,
} from "../../../__generated__/graphql-wp-sdk"
import Button from "../../base/button"
import WpContent from "../textBlock/wpContent"
import { FormWrapper, StyledForm } from "./form"

type wpFieldNode = Maybe<FormFieldNodeFragment | PreviewFormFieldNodeFragment>

export type FormState = string[]

interface IProps {
  block: Maybe<FormFragment | PreviewFormFragment>
  initState?: FormState
  succesMessage?: React.ReactNode
  bigHeader?: boolean
}

type SelectEvent = {
  name?: string | undefined
  value: unknown
}

const InputTextField: React.FC<TextFieldProps> = props => (
  <TextField name={props.id} fullWidth color="secondary" variant="outlined" {...props} />
)

const Separator = styled(Divider)`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(5)}px;
  background-color: ${({ theme }) => theme.palette.tertiary.main};
`

const Form: React.FC<IProps> = ({ block, initState, succesMessage, bigHeader }) => {
  const client = useGqlClient()
  const wpFields =
    block?.__typename === "WpForm"
      ? block.wpFields
      : block?.__typename === "Form"
      ? block.fields
      : {}
  const title = block?.title
  const showTitle = block?.showTitle
  const formId = block?.formId
  const [formData, setFormData] = useState<FormState>(initState || [])
  const [loading, setLoading] = useState(false)
  const init = "init"
  const [succes, setSucces] = useState<"init" | boolean>(init)

  useEffect(() => {
    wpFields?.nodes?.forEach(field => {
      if (field?.__typename === "WpListcountryField" || field?.__typename === "ListcountryField") {
        update(Number(field?.fieldId), "NL")
      }
    })
  }, [])

  const submit = async (e: React.FormEvent<HTMLInputElement>) => {
    if (typeof formData[0] === "undefined") {
      e.preventDefault()
      setLoading(true)

      const data: InputField[] = formData
        .map((value, index) => (value ? { id: index, value } : {}))
        .filter(el => el.hasOwnProperty("id"))

      try {
        const res = await client.SubmitForm({
          input: {
            clientMutationId: "submitForm",
            formId,
            data,
          },
        })

        console.log(res)
        setSucces(Boolean(res.submitForm?.success))
        setLoading(false)
      } catch (err) {
        console.log(err)
        setLoading(false)
        setSucces(false)
      }
    }
  }

  const update = (i: number, v: string) => {
    const newArray = [...formData]
    newArray[i] = v
    setFormData(newArray)
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index?: number
  ) => {
    const i = typeof index !== "undefined" ? index : Number(e.currentTarget.id)
    update(i, e.currentTarget.value)
  }

  const handleSelect = (e: React.ChangeEvent<SelectEvent>, child: ReactNode) => {
    const i = Number(e.target.name)
    update(i, String(e.target.value))
  }

  const handleDate = (date: MaterialUiPickersDate, index: number) => {
    const newDate = String(date?.toISOString())
    update(index, newDate)
  }

  const reset = () => {
    setFormData(initState || [])
    setLoading(false)
    setSucces(init)
  }

  if (succes === init) {
    return (
      <FormWrapper>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="nl">
          <StyledForm elevation={0} onSubmit={submit} isLoading={loading}>
            {showTitle && (
              <Typography
                variant={bigHeader ? "h1" : "h2"}
                component="h1"
                color="secondary"
                align="center"
              >
                {title}
              </Typography>
            )}
            <InputTextField
              id="field"
              name="field"
              className="noshow"
              onChange={e => handleChange(e, 0)}
              disabled={loading}
            />

            {wpFields?.nodes?.map((field: wpFieldNode) => {
              const id = Number(field?.fieldId)
              const label = String(field?.fieldLabel)
              const type = String(field?.type)
              const required = Boolean(field?.required)

              switch (field?.__typename) {
                case "WpSubmitField":
                  return (
                    <Button key={field.fieldId} color="blue" submit disabled={loading}>
                      {loading ? field.processingLabel : label}
                    </Button>
                  )
                case "WpTextareaField":
                  if (field.default === "verborgen") return null

                  return (
                    <InputTextField
                      key={field.fieldId}
                      id={String(id)}
                      label={label}
                      type={type}
                      required={required}
                      multiline
                      rows={6}
                      value={formData[id] || ""}
                      onChange={handleChange}
                      disabled={loading}
                    />
                  )
                case "WpListcountryField":
                  return (
                    <Select
                      key={field.fieldId}
                      id={String(id)}
                      name={String(id)}
                      value={formData[id] || "NL"}
                      onChange={handleSelect}
                      disabled={loading}
                      fullWidth
                      color="secondary"
                      variant="outlined"
                    >
                      <MenuItem value="NL">Nederland</MenuItem>
                      <MenuItem value="BE">België</MenuItem>
                      <MenuItem value="DE">Duitsland</MenuItem>
                    </Select>
                  )
                case "WpHrField":
                  return <Separator key={field.fieldId} variant="middle" />
                case "WpHiddenField":
                  return null
                case "WpDateField":
                  return (
                    <DatePicker
                      key={field.fieldId}
                      id={String(id)}
                      name={String(id)}
                      value={formData[id] || new Date()}
                      onChange={date => handleDate(date, id)}
                      disabled={loading}
                      fullWidth
                      color="secondary"
                      inputVariant="outlined"
                      minDate={new Date()}
                      format={field.dateFormat || undefined}
                      label={label}
                      showTodayButton
                      cancelLabel="Annuleren"
                      todayLabel="Vandaag"
                      allowKeyboardControl
                      required
                    />
                  )
                default:
                  return (
                    <InputTextField
                      key={field?.fieldId}
                      id={String(id)}
                      label={label}
                      type={type}
                      required={required}
                      value={formData[id] || ""}
                      onChange={handleChange}
                      disabled={loading}
                    />
                  )
              }
            })}
          </StyledForm>
        </MuiPickersUtilsProvider>
      </FormWrapper>
    )
  }

  if (succes) {
    return (
      <Container maxWidth="sm">
        <WpContent>
          <section style={{ textAlign: "center" }}>
            {succesMessage ? (
              succesMessage
            ) : (
              <>
                <h1>Bedankt!</h1>
                <p>
                  <strong>Je bericht is succesvol bij ons aangekomen.</strong>
                </p>
                <p>We laten zo snel mogelijk wat van ons horen.</p>
                <p>Team PUP</p>
              </>
            )}
            <Link to="/">Terug naar de home-pagina</Link>
          </section>
        </WpContent>
      </Container>
    )
  } else {
    return (
      <Container maxWidth="sm">
        <WpContent>
          <section style={{ textAlign: "center" }}>
            <h1>Helaas!</h1>
            <p>
              <strong>Er is iets mis gegaan.</strong>
            </p>
            <p>
              Probeer het anders via de telefoon, whatsapp of email. Of probeer het opnieuw door op
              onderstaande knop te drukken.
            </p>
            <p>Team PUP</p>
            <Button color="blue" onClick={reset}>
              Opnieuw
            </Button>
          </section>
        </WpContent>
      </Container>
    )
  }
}

export default Form
